import React from "react";
import PropTypes from "prop-types";
import Layout from "components/layout.my";
import SEO from "components/seo";

function NotFoundPage(props) {
  return (
    <Layout location={props.location}>
      <SEO title="404: Not found" />
      <div className="w-full text-center">
        <h2 className="leading-loose text-tainted-blue text-2xl font-bold inline-block my-8 p-3">
          စိတ်မကောင်းပါ။ သင်တောင်းဆိုသော စာမျက်နှာ မရှိပါ။
          <br />
          Sorry... We cannot find the page you requested!
        </h2>
      </div>
    </Layout>
  );
}

NotFoundPage.propTypes = {
  location: PropTypes.any,
};

export default NotFoundPage;
